/* FONT */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,500&display=swap");
/* COLORS */
/* BREAKPOINTS */
/* BODY */
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-family: 'Poppins', sans-serif;
  color: #3f3844; }

a {
  color: inherit;
  text-decoration: none; }

a:focus,
input:focus,
button:focus,
*:focus {
  outline: none; }

.content {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 1em; }

@media (max-width: 600px) {
  h1 {
    font-size: 1.5em; } }

@media (max-width: 450px) {
  .content {
    padding-left: 0.5em;
    padding-right: 0.5em; } }

i.fa {
  margin-right: 0.2em; }

/* FLEXBOX */
.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

.flex-col {
  display: flex;
  flex-direction: column; }

.flex-centered {
  justify-content: center;
  align-items: center; }

.flex-end {
  justify-content: flex-end; }

/* LOADER */
@keyframes loadingAnimation {
  0% {
    opacity: 0%;
    height: 10%; }
  100% {
    opacity: 100%;
    height: 11%; } }

@keyframes fadeoutAnimation {
  70% {
    opacity: 100%; }
  100% {
    opacity: 0%; } }

.loader {
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  animation: 1s fadeoutAnimation;
  -webkit-animation: 1s fadeoutAnimation;
  display: flex;
  justify-content: center;
  align-items: center; }
  .loader img {
    height: 10%;
    animation: 1s loadingAnimation;
    -webkit-animation: 1s loadingAnimation; }

/* COOKIES */
.cookies-bar {
  z-index: 100;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #008c96;
  color: #fff; }
  .cookies-bar .content {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center; }
  .cookies-bar-text a {
    text-decoration: underline; }
  .cookies-bar-button {
    background-color: #fff;
    color: #008c96;
    cursor: pointer;
    border: none;
    border-radius: 1em;
    margin: 0.5em;
    padding: 0.5em 2em;
    transition: 0.3s; }
    .cookies-bar-button:hover {
      color: #007078; }
    .cookies-bar-button.negative {
      background-color: #007078;
      color: #fff; }
      .cookies-bar-button.negative:hover {
        color: #fff; }

@media (max-width: 900px) {
  .cookies-bar .content {
    flex-wrap: wrap;
    justify-content: end;
    text-align: center;
    margin-bottom: 1em; } }

/* HEADER BAR */
.header-bar {
  width: 100%;
  background-color: #008c96;
  color: #fff; }
  .header-bar .content {
    justify-content: space-between; }
  .header-bar p,
  .header-bar a {
    color: #fff;
    margin: 0.7em 0 0.5em;
    text-decoration: none; }
    .header-bar p .fa,
    .header-bar a .fa {
      margin-right: 0.4em; }
  .header-bar i.fa {
    margin-left: 0.2em; }
  .header-bar .left {
    flex-basis: 250px; }
  .header-bar .right {
    flex-basis: 500px;
    justify-content: space-between; }
  .header-bar .social i.fa {
    cursor: pointer;
    font-size: 1.2em; }
  .header-bar .social .lang {
    cursor: pointer;
    margin-left: 0.2em;
    margin-bottom: -0.15em;
    height: 1.2em; }

@media (max-width: 900px) {
  .header-bar .left {
    display: none; }
  .header-bar .right {
    flex-basis: 100%; } }

@media (max-width: 600px) {
  .header-bar .fa {
    margin-right: 0.2em !important;
    margin-left: 0.1em !important; } }

@media (max-width: 450px) {
  .header-bar .fa {
    display: none; } }

/* MENU */
.menu {
  position: absolute;
  top: 3em;
  width: 100%;
  transition: background-color 0.2s;
  z-index: 99; }
  .menu .content {
    justify-content: space-between;
    -webkit-filter: drop-shadow(0 0 20px #000);
    filter: drop-shadow(0 0 20px #000); }
    .menu .content .logo {
      cursor: pointer;
      height: 10em;
      transition: height 0.4s; }
    .menu .content .right {
      display: flex;
      flex-direction: row; }
    .menu .content p {
      margin-top: 2em;
      padding-left: 2em;
      color: #fff;
      transition: padding-left 0.2s;
      font-weight: 500;
      cursor: pointer; }
      .menu .content p:hover, .menu .content p.active {
        text-decoration: underline; }
    .menu .content .fa {
      display: none;
      cursor: pointer;
      color: white;
      font-size: 2em; }
  .menu.pinned {
    position: fixed;
    top: 0;
    background-color: #008c96;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); }
    .menu.pinned .content {
      -webkit-filter: none;
      filter: none; }
      .menu.pinned .content .logo {
        height: 2.8em;
        margin: 0.2em 0;
        filter: invert(100%);
        -webkit-filter: invert(100%); }
      .menu.pinned .content p {
        margin-top: 1em;
        padding-left: 1em;
        color: #fff; }
        .menu.pinned .content p:hover, .menu.pinned .content p.active {
          text-decoration: underline; }

@media (max-width: 900px) {
  .menu .content .logo {
    height: 8em; }
  .menu .content p {
    padding-left: 1em; } }

@media (max-width: 600px) {
  .menu .content .logo {
    height: 5em; }
  .menu .content .right {
    z-index: 100;
    flex-direction: column;
    align-items: end;
    -webkit-filter: drop-shadow(0 0 20px #000) drop-shadow(0 0 10px #000);
    filter: drop-shadow(0 0 20px #000) drop-shadow(0 0 10px #000); }
    .menu .content .right.open .fa {
      margin-bottom: 0.5em; }
    .menu .content .right.open p {
      display: block;
      margin-top: 0.5em; }
  .menu .content .fa {
    display: block;
    margin-top: 0.35em; }
  .menu .content p {
    display: none; }
  .menu.pinned .content .right {
    -webkit-filter: none !important;
    filter: none !important; } }

/* HEADER BANNER */
.header-banner {
  color: #fff;
  height: 60vh;
  min-height: 40em;
  background-image: url("../assets/cover.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 100vh rgba(0, 0, 0, 0.2); }
  .header-banner .header-overlay {
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center; }
  .header-banner .content {
    width: 100%;
    margin-top: 2em;
    font-weight: 500;
    -webkit-filter: drop-shadow(0 0 20px #000);
    filter: drop-shadow(0 0 20px #000); }
    .header-banner .content h1 {
      font-size: 2.5em; }
    .header-banner .content .button {
      background-color: #008c96;
      color: #fff;
      cursor: pointer;
      border: none;
      border-radius: 1em;
      width: fit-content;
      transition: 0.3s;
      padding: 0.5em 2em;
      font-size: 1.2em; }
      .header-banner .content .button a {
        color: #fff;
        text-decoration: none; }
      .header-banner .content .button:hover {
        background-color: #007078; }
    .header-banner .content p {
      color: #fff;
      cursor: pointer;
      font-size: 1.2em;
      text-decoration: none; }
      .header-banner .content p:hover {
        text-decoration: underline; }

@media (max-width: 600px) {
  .header-banner {
    text-align: center; }
    .header-banner h1 {
      font-size: 2em !important;
      align-self: center; }
    .header-banner .button {
      font-size: 1em !important;
      align-self: center; }
    .header-banner p {
      font-size: 1em !important;
      align-self: center; } }

/* INTRO */
.intro {
  justify-content: space-between;
  align-items: end; }
  .intro .logo {
    cursor: pointer;
    height: 5em; }
  .intro-img {
    flex-basis: 40%; }
    .intro-img img {
      max-height: 35em;
      max-width: 100%;
      margin: 0 auto;
      -webkit-filter: drop-shadow(5px 5px 15px #3f3844);
      filter: drop-shadow(5px 5px 15px #3f3844); }
  .intro .text {
    margin: 5em 0;
    flex-basis: 50%;
    text-align: center; }
    .intro .text-red {
      color: #c90000;
      text-decoration: underline; }
    .intro .text .more {
      color: #008c96; }
      .intro .text .more:hover {
        text-decoration: underline;
        cursor: pointer; }
    .intro .text .button {
      cursor: pointer;
      margin: 5em auto 0;
      background-color: #c90000;
      border-radius: 1em;
      width: fit-content;
      transition: 0.3s;
      padding: 0.5em 2em;
      font-weight: 500;
      border: solid 0.1em #c90000; }
      .intro .text .button a {
        color: #fff;
        text-decoration: none; }
      .intro .text .button i.fa {
        margin-right: 0.5em; }
      .intro .text .button:hover {
        background-color: #fff; }
        .intro .text .button:hover a {
          color: #c90000; }

@media (max-width: 900px) {
  .intro-img {
    flex-basis: 100%; }
  .intro .text {
    margin-bottom: 0;
    flex-basis: 100%; } }

/* PRICING */
.pricing {
  margin: 5em auto; }
  .pricing .content {
    align-items: center; }
  .pricing-wrapper h1 {
    margin-bottom: 1em; }
  .pricing-info {
    margin: 1em auto 5em;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center; }
  .pricing-thumbnail {
    margin: 1em;
    max-width: 15em;
    max-height: 25em;
    position: relative;
    filter: drop-shadow(5px 5px 15px #3f3844);
    -webkit-filter: drop-shadow(5px 5px 15px #3f3844); }
    .pricing-thumbnail img {
      height: 100%;
      width: 100%; }
    .pricing-thumbnail-show {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      color: #fff;
      font-weight: 500;
      box-shadow: inset 0 0 0 100vh rgba(0, 0, 0, 0.8);
      transition: 0.3s; }
      .pricing-thumbnail-show i {
        font-size: 2.5em;
        transition: 0.3s;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%); }
      .pricing-thumbnail-show p {
        font-size: 1.2em;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 100%; }
      .pricing-thumbnail-show:hover {
        box-shadow: inset 0 0 0 100vh rgba(0, 0, 0, 0.9); }
        .pricing-thumbnail-show:hover i {
          font-size: 3em; }
  .pricing-text {
    margin: 1em;
    align-self: flex-end;
    max-width: 25em; }
  .pricing .features {
    flex-wrap: nowrap;
    justify-content: center; }
    .pricing .features .feature {
      cursor: pointer;
      display: flex !important;
      justify-content: center;
      text-align: center;
      position: relative;
      color: #fff; }
      .pricing .features .feature-photo {
        width: 100%; }
      .pricing .features .feature-overlay {
        position: absolute;
        bottom: 0em;
        width: 100%;
        height: 5em;
        box-shadow: inset 0 0 0 100vh rgba(0, 112, 120, 0.9);
        transition: 0.3s; }
      .pricing .features .feature-name {
        position: absolute;
        bottom: 0em;
        width: 90%;
        font-weight: 600;
        font-size: 1.5em;
        transition: 0.5s; }
      .pricing .features .feature-text {
        opacity: 0;
        position: absolute;
        top: 40%;
        transform: translate(0, -50%);
        width: 70%;
        line-height: 1.5em;
        transition: 0.5s; }
      .pricing .features .feature:hover .feature-overlay {
        height: 100%; }
      .pricing .features .feature:hover .feature-name {
        bottom: 0.5em; }
      .pricing .features .feature:hover .feature-text {
        opacity: 100; }

@media (max-width: 900px) {
  .pricing-info {
    flex-wrap: wrap; }
  .pricing-text {
    max-width: 35em;
    text-align: center; }
  .pricing .features {
    flex-wrap: wrap; }
    .pricing .features .feature {
      margin: 1em; } }

@media (max-width: 600px) {
  .pricing .features .feature-overlay {
    height: 4em; }
  .pricing .features .feature-name {
    font-size: 1.2em; }
  .pricing .features .feature-text {
    width: 90%; } }

@media (max-width: 450px) {
  .pricing-wrapper h1 {
    width: 100%;
    text-align: center; }
  .pricing .features .feature-overlay {
    height: 3em; }
  .pricing .features .feature-name {
    font-size: 1em; }
  .pricing .features .feature-text {
    top: 33%;
    width: 95%;
    font-size: 0.7em; } }

/* GOOGLE REVIEWS */
.greviews {
  width: 100%;
  padding: 3em 0;
  color: #fff;
  background-image: url("../assets/reviews_banner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 100vh rgba(0, 112, 120, 0.9);
  overflow: hidden; }
  .greviews .content {
    display: flex;
    justify-content: center; }
  .greviews .slider {
    max-width: 60%; }
  .greviews .review {
    height: 20em;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    text-align: center; }
    .greviews .review-text {
      line-height: 2em; }
    .greviews .review-author {
      font-size: 0.9em; }
  .greviews .slick-dots li button:before {
    color: #fff; }

@media (max-width: 900px) {
  .greviews .slider {
    max-width: 100%; } }

@media (max-width: 600px) {
  .greviews .review {
    height: 25em;
    font-size: 0.8em; } }

/* NEWS */
.news {
  margin: 3em 0 5em; }
  .news-text {
    text-align: center;
    font-size: 1.2em; }
    .news-text a {
      margin-left: 0.4em;
      color: #008c96; }
      .news-text a:hover {
        text-decoration: underline; }
  .news .fb-page {
    max-width: 500px;
    height: 180px;
    margin: 3em auto 1em;
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #fff;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(255, 255, 255, 0)), url("../assets/team_banner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    filter: drop-shadow(5px 5px 15px #3f3844);
    -webkit-filter: drop-shadow(5px 5px 15px #3f3844); }
    .news .fb-page-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      filter: drop-shadow(5px 5px 15px #3f3844);
      -webkit-filter: drop-shadow(5px 5px 15px #3f3844);
      cursor: pointer; }
    .news .fb-page-logo {
      height: 50px;
      width: 50px;
      background-color: #fff;
      padding: 3px;
      border-radius: 2px; }
    .news .fb-page-title {
      margin-left: 1em;
      font-size: 0.8em; }
      .news .fb-page-title .page-title {
        font-size: 1.5em;
        font-weight: 500; }
    .news .fb-page-controls {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      filter: drop-shadow(5px 5px 15px #3f3844);
      -webkit-filter: drop-shadow(5px 5px 15px #3f3844);
      cursor: pointer; }
    .news .fb-page-control {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: #fff;
      border-radius: 3px;
      color: #3f3844;
      line-height: 0;
      padding: 1px 10px;
      font-size: 0.8em;
      font-weight: 500;
      transition: 0.3s; }
      .news .fb-page-control .fa {
        margin-right: 0.5em; }
      .news .fb-page-control:hover {
        box-shadow: inset 0 0 0 100vh rgba(0, 0, 0, 0.1); }

/* INSTAGRAM */
.instagram {
  margin: 3em 0 5em; }
  .instagram-header {
    align-items: center; }
    .instagram-header .logo {
      cursor: pointer;
      height: 4em;
      margin: 0.7em; }
    .instagram-header .text {
      cursor: pointer;
      font-size: 1.2em; }
  .instagram-wrapper {
    margin-top: 1em;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; }
    .instagram-wrapper .feed {
      cursor: pointer;
      width: 293px;
      height: auto;
      margin: 1em; }
      .instagram-wrapper .feed img {
        width: 100%; }
  .instagram .follow-link {
    text-align: center; }
    .instagram .follow-link a {
      margin-left: 0.4em;
      color: #008c96; }
      .instagram .follow-link a:hover {
        text-decoration: underline; }

@media (max-width: 600px) {
  .instagram-wrapper .feed {
    width: 170px;
    margin: 0.5em; } }

/* TEAM */
.team-banner {
  width: 100%;
  height: 30em; }
  .team-banner-image {
    width: 100%;
    height: 30em;
    background-image: url("../assets/team_banner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    box-shadow: inset 0 0 0 100vh rgba(0, 112, 120, 0.5); }

@media (max-width: 600px) {
  .team-banner {
    height: 20em; }
    .team-banner-image {
      height: 20em;
      transform: translate3d(0, 0, 0); } }

.team {
  display: flex;
  flex-direction: column;
  padding: 5em 1em; }
  .team-header {
    margin-left: 3em; }
  .team-text {
    max-width: 60%;
    text-align: center;
    align-self: center; }
  .team-members {
    margin-top: 3em; }
  .team-member {
    height: 20em;
    cursor: pointer;
    position: relative;
    display: flex !important;
    justify-content: center;
    text-align: center;
    margin-bottom: 1em; }
    .team-member-circle {
      width: 15em;
      height: 15em;
      position: relative;
      border-radius: 50%;
      overflow: hidden;
      top: 2em; }
    .team-member-photo {
      height: 20em;
      position: absolute;
      top: -2em; }
    .team-member-name {
      width: 15em;
      z-index: 1;
      position: absolute;
      top: 17em;
      margin: 1em;
      font-weight: 600;
      transition: 0.3s; }
    .team-member-text {
      width: 14em;
      z-index: 1;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      margin: 1em;
      opacity: 0;
      transition: 0.5s; }
    .team-member:hover {
      color: #fff; }
      .team-member:hover .team-member-circle {
        height: 100%;
        border-radius: 1em;
        top: 0; }
      .team-member:hover .team-member-photo {
        top: 0; }
      .team-member:hover .team-member-overlay {
        z-index: 1;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        box-shadow: inset 0 0 0 100vh rgba(0, 112, 120, 0.9); }
      .team-member:hover .team-member-name {
        z-index: 2;
        top: 0.5em; }
      .team-member:hover .team-member-text {
        z-index: 2;
        opacity: 100; }

@media (max-width: 900px) {
  .team-header {
    margin-left: 2em; }
  .team-text {
    max-width: 80%; } }

@media (max-width: 600px) {
  .team-header {
    margin-left: 0.5em; }
  .team-text {
    max-width: 100%; } }

/* DEVICES */
.devices {
  background-color: #008c96;
  color: #fff;
  padding: 3em 0; }
  .devices-wrapper {
    margin: 1em auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center; }
  .devices-text {
    width: 100%;
    text-align: center;
    margin-top: 3em; }
  .devices .device {
    cursor: pointer;
    width: 5em;
    height: 5em;
    margin: 1em;
    position: relative;
    transition: 0.3s; }
    .devices .device-image {
      width: 100%;
      height: 100%;
      border-radius: 5px; }
    .devices .device-text {
      position: absolute;
      z-index: 1;
      visibility: hidden;
      background-color: #000;
      color: #fff;
      text-align: center;
      padding: 5px;
      border-radius: 5px;
      width: 150px;
      bottom: 100%;
      left: 50%;
      transform: translate(-50%, 0);
      font-size: 0.8em; }
      .devices .device-text::after {
        content: ' ';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border: solid 5px;
        border-color: #000 transparent transparent transparent; }
    .devices .device:hover {
      width: 6em;
      height: 6em;
      margin: 0.5em; }
      .devices .device:hover .device-text {
        visibility: visible; }

/* CONTACT */
.contact-tabs {
  justify-content: space-around;
  margin: 2em auto; }

.contact-tab {
  text-align: center;
  margin: 2em; }
  .contact-tab .fa {
    font-size: 2em;
    color: #008c96; }
  .contact-tab p {
    margin: 0.2em; }
  .contact-tab-header {
    font-weight: 500;
    line-height: 2em; }
  .contact-tab a {
    line-height: 2em;
    color: #008c96; }
    .contact-tab a:hover {
      text-decoration: underline; }

.contact-form {
  margin: 4em auto;
  padding: 2em;
  border: solid 1px;
  border-radius: 1em; }
  .contact-form-wrapper {
    justify-content: space-between; }
  .contact-form-left {
    flex-basis: 35%; }
  .contact-form-right {
    flex-basis: 58%; }
  .contact-form-controls {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    position: relative; }
    .contact-form-controls .submit-button {
      cursor: pointer;
      background-color: #008c96;
      color: #fff;
      border: none;
      border-radius: 1em;
      width: 10em;
      margin-top: 2em;
      padding: 0.5em 1em;
      font-weight: 500;
      transition: 0.3s; }
      .contact-form-controls .submit-button:hover {
        background-color: #007078; }
      .contact-form-controls .submit-button[disabled]:hover {
        background-color: #3f3844;
        cursor: no-drop; }
    .contact-form-controls .submit-message {
      font-size: 0.8em;
      position: absolute;
      top: 5.5em;
      max-width: 100%;
      left: 50%;
      transform: translateX(-50%); }
    .contact-form-controls .form-captcha {
      justify-content: flex-start;
      position: relative; }
    .contact-form-controls .recaptcha {
      margin-right: 1em; }
    .contact-form-controls .recaptcha-disclaimer {
      position: absolute;
      top: -5px;
      left: 320px;
      width: 100px;
      font-size: 0.6em; }
  .contact-form-field {
    width: 100%;
    margin-bottom: 1em;
    position: relative; }
  .contact-form input {
    width: 96%;
    border: none;
    border-bottom: solid 1px;
    border-radius: 0;
    padding: 0.5em; }
  .contact-form .textarea {
    width: 98%;
    height: 5em;
    border: none;
    border-bottom: solid 1px;
    border-radius: 0;
    padding: 0.5em;
    resize: vertical; }
  .contact-form .field-error {
    position: absolute;
    right: 0;
    top: 10%;
    color: #c90000; }

@media (max-width: 900px) {
  .contact-form-left {
    flex-basis: 100%; }
  .contact-form-right {
    flex-basis: 100%; } }

@media (max-width: 700px) {
  .contact-form-controls {
    flex-direction: column; }
    .contact-form-controls .submit-message {
      top: 10.5em; }
    .contact-form-controls .recaptcha-disclaimer {
      top: 7em;
      left: 0;
      width: 300px; } }

/* FOOTER */
.footer {
  background-color: #008c96;
  color: #fff; }
  .footer .flex-row {
    justify-content: center;
    align-items: center; }
  .footer .flex-col {
    flex-basis: 20%;
    margin: 1em;
    align-items: center;
    text-align: center;
    min-height: 12em;
    padding-top: 2em; }
    .footer .flex-col p,
    .footer .flex-col a {
      margin: 0.4em; }
    .footer .flex-col a {
      text-decoration: underline; }
    .footer .flex-col b {
      margin: 0.7em 0.5em; }
    .footer .flex-col .fa {
      cursor: pointer;
      font-size: 1.8em;
      margin: 0 0.2em; }
  .footer .sponsor {
    max-width: 15em;
    margin-bottom: 1.5em;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(73deg) brightness(100%) contrast(103%);
    -webkit-filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(73deg) brightness(100%) contrast(103%); }
  .footer .copyright {
    margin-top: 1em; }

@media (max-width: 900px) {
  .footer .flex-col {
    flex-basis: 45%;
    min-height: 5em; } }

.placeholder {
  border: solid 1px silver;
  height: 20em;
  padding: 1em;
  text-align: center; }
  .placeholder.color {
    background-color: #008c96;
    color: #fff; }

.metadata,
.metadata > * {
  visibility: hidden;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0; }
